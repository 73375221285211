'use client';

import { useState } from 'react';
import { useParams, useRouter } from 'next/navigation';
import { Button, Form, FormGroup, Input, InputGroup } from 'reactstrap';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { MdClose, MdSearch, MdOutlineLocationOn } from 'react-icons/md';

import { event } from '../utils/gtag';
import { plausibleEvent } from '../utils/plausibleEvent';
import { getLocationsForAutocomplete } from '../api/locations';
import { getI18nUtils } from '../i18n/i18n-utils';

import queryString from 'query-string';
import { Dictionaries } from '../i18n/i18n-config';

const app = process.env.NEXT_PUBLIC_APP;

const MaxLengthInput = (props) => {
  const { selectProps } = props;
  const { maxLength } = selectProps;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <components.Input {...props} maxLength={maxLength} />;
};

function IndexJobSearchForm({
  id,
  starterJobs,
  className,
  withHeader,
  dictionary,
}: {
  id?: string;
  starterJobs?: boolean;
  className?: string;
  withHeader?: boolean;
  dictionary: Partial<Dictionaries>;
}): JSX.Element {
  const router = useRouter();
  const { locale } = useParams();
  const [what, setWhat] = useState('');
  const [where, setWhere] = useState('');
  const [radius, setRadius] = useState('25');

  const parseLocation = (locationString) => {
    if (!locationString) return null;
    const [postalCode, city] = locationString.split('!');
    return { postalCode, city };
  };

  const [location, setLocation] = useState(parseLocation(where));
  const { formatMessage } = getI18nUtils(dictionary);

  function findJobs(e) {
    e.preventDefault();
    const queryParams: { what: string; where: string; experience?: string; radius?: string } = {
      what,
      where,
      radius,
    };
    if (starterJobs) queryParams.experience = 'experience-no-experience';

    const href = `/${locale}/jobs/?${queryString.stringify(queryParams, { skipEmptyString: true })}`;
    event({
      action: 'submit_indexJobsearchForm',
      category: app || 'synergiejobs.be',
      label: '',
      value: { what, where, radius },
    });
    plausibleEvent('jobSearch');
    router.push(href);
  }

  const handleLocationChange = (e) => {
    setLocation(e);
    setWhere(`${e.postalCode}!${e.city}`);
  };

  const sharedCustomStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : provided.color,
      background: state.isSelected ? '#e2001a' : provided.background,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '0',
    }),
    input: (basis) => ({
      ...basis,
      paddingLeft: '8px',
    }),
    singleValue: (basis) => ({
      ...basis,
      paddingLeft: '8px',
    }),
    placeholder: (base) => ({
      ...base,
      overflow: 'hidden',
      paddingLeft: '8px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  };

  const customLocationStyles = {
    ...sharedCustomStyles,
    control: (base) => ({
      ...base,
      border: 'none',
      borderRight: '#ced4da 1px solid',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      boxShadow: 'none',
      padding: '0 24px 0 30px',
      '&:hover': {
        borderRight: '#ced4da 1px solid',
      },
    }),
  };

  const customRadiusStyles = {
    ...sharedCustomStyles,
    control: (base) => ({
      ...base,
      border: 'none',
      borderLeft: '#ced4da 1px solid',
      borderTopLeftRadius: '0',
      borderBottomLeftRadius: '0',
      boxShadow: 'none',
      '&:hover': {
        borderLeft: '#ced4da 1px solid',
      },
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: 'black',
      padding: '0',
      paddingRight: '6px',
      '&:hover': {
        color: 'black',
      },
    }),
  };

  const radiusOptions = [
    { label: '5km', value: '5' },
    { label: '10km', value: '10' },
    { label: '25km', value: '25' },
    { label: '50km', value: '50' },
    { label: '75km', value: '75' },
    { label: '100km', value: '100' },
  ];

  return (
    <div className={`job-search-form w-100 indexJobSearchForm ${className || ''}`} id={id || ''}>
      {withHeader && <h3>{formatMessage({ id: 'index-h1' })}</h3>}
      <Form
        onSubmit={(e) => findJobs(e)}
        className="w-100 d-flex flex-column flex-lg-row justify-content-space-between flex-wrap"
      >
        <FormGroup className="field-container">
          <InputGroup className="d-flex w-100">
            <Input
              style={{ zIndex: 3 }}
              onChange={(e) => setWhat(e.target.value)}
              type="text"
              name="what"
              id="what"
              placeholder={formatMessage({ id: 'index-search-block-what-label' })}
              value={what}
            />
            <MdSearch className="search-field-icon" size="24" />
            {what && what !== '' && (
              <Button className="btn-clear" color="text-primary" onClick={() => setWhat('')}>
                <MdClose />
              </Button>
            )}
          </InputGroup>
        </FormGroup>
        <FormGroup className="field-container">
          <InputGroup className="d-flex w-100">
            <AsyncSelect
              instanceId="location-select"
              components={{ DropdownIndicator: null, Input: MaxLengthInput }}
              name="where"
              id="where"
              placeholder={formatMessage({ id: 'index-search-block-where-label' })}
              value={location}
              loadOptions={getLocationsForAutocomplete}
              styles={customLocationStyles}
              defaultOptions
              className="location-select"
              getOptionLabel={(option) => `${option.postalCode} ${option.city}`}
              getOptionValue={(option) => `${option.postalCode} ${option.city}`}
              onChange={handleLocationChange}
            />
            <MdOutlineLocationOn className="search-field-icon" size="24" />
            {where && (
              <Button
                className="btn-clear btn-clear-location"
                color="text-primary"
                onClick={() => {
                  setWhere('');
                  setLocation(null);
                }}
              >
                <MdClose />
              </Button>
            )}
            <Select
              instanceId="radius-select"
              components={{ IndicatorSeparator: null }}
              name="radius"
              id="radius"
              className="radius-select"
              value={radiusOptions.find((o) => o.value === String(radius))}
              onChange={(e) => e && setRadius(e.value)}
              options={radiusOptions}
              styles={customRadiusStyles}
            />
          </InputGroup>
        </FormGroup>
        <Button type="submit" outline color="primary" className="text-white border-white top-level-button">
          <svg width="18px" height="18px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g fill="#ffffff" fillRule="nonzero">
                <path
                  d="M36.51,33 C39.3269542,29.4161256 40.8556865,24.9884377 40.85,20.43 C40.85,2.22 18.76,-6.79 6,6 C-1.57508682,13.6385253 -1.92078163,25.845292 5.20986587,33.9002827 C12.3405134,41.9552734 24.4990885,43.0926863 33,36.5 L46.15,49.66 L49.67,46.15 L36.51,33 Z M20.43,35.87 C6.61,35.87 -0.14,19.14 9.5,9.5 C19.14,-0.14 35.88,6.77 35.88,20.42 C35.8826553,24.5184082 34.2557448,28.4497191 31.3577319,31.3477319 C28.4597191,34.2457448 24.5284082,35.8726553 20.43,35.87 L20.43,35.87 Z"
                  id="Shape"
                />
              </g>
            </g>
          </svg>
          {formatMessage({ id: 'jobs-search-action' })}
        </Button>
      </Form>
    </div>
  );
}

export default IndexJobSearchForm;
