'use client';

import Link from 'next/link';
import dayjs from 'dayjs';
import slugify from 'slugify';
import { useParams } from 'next/navigation';
import htmlToDescription from '../utils/htmlToDescription';
import CtaBtn from './CtaBtn';
import { getI18nUtils } from '../i18n/i18n-utils';
import { Dictionaries } from '../i18n/i18n-config';

function EventTeaser({
  event,
  dictionary,
}: {
  event?: {
    title: string;
    date: { dateFrom: string; dateTill: string; startTime: string; endTime: string };
    description: string;
    location: {
      description: string;
      street: string;
      houseNumber: string;
      postalCode: string;
      city: string;
    };
    id: string;
  };
  dictionary: Partial<Dictionaries>;
}) {
  const { formatMessage } = getI18nUtils(dictionary);
  const { locale } = useParams();
  if (!event) return null;
  const { title, date, description, location, id } = event;

  const bg = '#FFFFFF';
  const color = 'rgb(33, 37, 41)';
  const borderColor = 'rgba(0,0,0,.125)';
  const iconColor = 'rgba(33, 37, 41, .25)';

  // const count = index + 1;
  // if (count === 1 || count === 4) {
  //   borderColor = '#E2001A';
  //   bg = '#E2001A';
  //   color = '#FFFFFF';
  //   iconColor = 'rgba(255,255,255,.35)';
  // } else if (index) {
  //   borderColor = '#FFB400';
  //   bg = '#FFB400';
  //   color = '#FFFFFF';
  //   iconColor = 'rgba(255,255,255,.35)';
  // }

  return (
    <Link
      style={{ backgroundColor: bg, color, borderColor }}
      className="card eventTeaser p-4 justify-content-between"
      href={`/${locale}/events/${id}/${slugify(title, { lower: true })}/`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <svg
          version="1.2"
          baseProfile="tiny"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="32px"
          height="32px"
          viewBox="0 0 64 64"
        >
          <g>
            <rect fill="none" width="64" height="64" />
          </g>
          <g>
            <g>
              <path
                fill={iconColor}
                d="M44,49h5v-5h-5V49z M50,25H13v5h37V25z M49,34h-5v5h5V34z M22,17h15v-5H22V4h-5v17h5V17z M39,34h-5v5h5V34
                        z M46,12V4h-5v17h5v-4h8v37H9V17h4v-5H4v47h55V12H46z M34,49h5v-5h-5V49z M14,49h5v-5h-5V49z M19,34h-5v5h5V34z M29,34h-5v5h5V34z
                        M24,49h5v-5h-5V49z"
              />
            </g>
          </g>
        </svg>
        <span className="text-uppercase">{formatMessage({ id: 'event-teaser-category' })}</span>
      </div>

      <div className="flex-grow-1">
        <h3 className="card-title mt-3 mb-3 font-weight-bold">{htmlToDescription(title, 90, 90)}</h3>
        <p> {htmlToDescription(description, 90, 90)}</p>
        {date && (
          <time
            className="d-inline-block me-4 mt-2"
            dateTime={dayjs(`${date.dateFrom} ${date.startTime}`, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm')}
          >
            {date.dateFrom && (
              <span className="me-3">
                <div className="iconContainer">
                  <svg
                    className="me-2"
                    version="1.2"
                    baseProfile="tiny"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    height="16px"
                    width="16px"
                    viewBox="0 0 64 64"
                  >
                    <g>
                      <rect fill="none" width="64" height="64" />
                    </g>
                    <g>
                      <g>
                        <path
                          fill="#E9E9E9"
                          d="M44,49h5v-5h-5V49z M50,25H13v5h37V25z M49,34h-5v5h5V34z M22,17h15v-5H22V4h-5v17h5V17z M39,34h-5v5h5V34
                        z M46,12V4h-5v17h5v-4h8v37H9V17h4v-5H4v47h55V12H46z M34,49h5v-5h-5V49z M14,49h5v-5h-5V49z M19,34h-5v5h5V34z M29,34h-5v5h5V34z
                        M24,49h5v-5h-5V49z"
                        />
                      </g>
                    </g>
                  </svg>
                </div>

                <span>{date.dateFrom}</span>
              </span>
            )}
            {date.startTime && (
              <span>
                <div className="iconContainer">
                  <svg className="me-2" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" viewBox="0 0 11.441 15">
                    <path
                      id="Path_233"
                      data-name="Path 233"
                      d="M1425.7,1545.864h.184v1.271h-3.305v-3.305h1.271v2.034h1.85Zm3.743,0v1.271h-1.073a5.134,5.134,0,0,1-1.469,3.041v2.167a1.669,1.669,0,0,1-1.666,1.657h-4.068a1.647,1.647,0,0,1-1.639-1.657v-2.167a5.2,5.2,0,0,1,0-7.361v-2.167a1.64,1.64,0,0,1,1.639-1.648h4.068a1.662,1.662,0,0,1,1.666,1.648v2.167a5.157,5.157,0,0,1,1.469,3.049Zm-3.813,5.246a5.178,5.178,0,0,1-4.831,0v1.233a.374.374,0,0,0,.368.386h4.068a.4.4,0,0,0,.395-.386Zm-.007-7.721a3.907,3.907,0,0,0-3.807-.568v-1.336a5.259,5.259,0,0,1,3.814.4v-1.233a.39.39,0,0,0-.395-.377h-4.068a.367.367,0,0,0-.368.377v2.74a3.932,3.932,0,1,0,4.824,0Z"
                      transform="translate(-1418 -1539)"
                      fill="#e9e9e9"
                    />
                  </svg>
                </div>
                <span>{date.startTime}</span>
              </span>
            )}
          </time>
        )}
        {location && location.city !== null && (
          <address className="d-inline-block mt-2 mb-0">
            <div className="iconContainer">
              <svg
                className="me-2"
                xmlns="http://www.w3.org/2000/svg"
                // width="10.79"
                height="16px"
                width="16px"
                viewBox="0 0 10.79 15"
              >
                <path
                  id="Path_234"
                  data-name="Path 234"
                  d="M786.035,904.859a1.711,1.711,0,1,1-1.711-1.711A1.711,1.711,0,0,1,786.035,904.859Zm3.684.544c0,3.124-1.935,5.14-5.395,9.588-3.478-4.466-5.4-6.473-5.4-9.588a5.4,5.4,0,1,1,10.79,0Zm-5.395,7.5c2.564-3.3,4.122-5.023,4.122-7.52a4.122,4.122,0,1,0-8.244,0C780.2,907.879,781.757,909.6,784.324,912.907Z"
                  transform="translate(-778.929 -899.991)"
                  fill="#e9e9e9"
                />
              </svg>
            </div>
            <span>{`${location.street} ${location.houseNumber}, ${location.postalCode} ${location.city}`}</span>
          </address>
        )}
      </div>
      <div className="ctaContainer mt-4 d-flex justify-content-end">
        <CtaBtn className="cta mt-2">Lees meer</CtaBtn>
      </div>
    </Link>
  );
}

export default EventTeaser;
