import axiosInstance from '../utils/axios';
import { logSentryError } from '../utils/logSentry';

export type ApiLocation = {
  lat: string;
  lng: string;
  postalCode: string;
  city: string;
};

export const getLocationsForAutocomplete = async (search: string = '') => {
  try {
    const { data } = await axiosInstance.get<ApiLocation[]>(`/autocomplete/locations`, { params: { search } });

    return data;
  } catch (error) {
    logSentryError(error);
  }
  return [];
};
