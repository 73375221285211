export default function htmlToDescription(html, startCount = 160, maxlength = 200) {
  if (typeof html !== 'string') return '';

  let description = html
    .replace(/[ ]*<\/li>/g, '.') // replace closing li tag including possible preceding spaces with a dot
    .replace(/\.\./g, '.') // replace two dots with a single dot
    .replace(/<[^>]*>/g, ' ') // replace all html tags with a space
    .replace(/\s{2,}/g, ' ') // replace two or more spaces with one space
    .replace(/&amp;/g, '&')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');

  if (description.length > startCount) {
    const lastSpace = description.substring(0, maxlength).lastIndexOf(' ');
    description = `${description.substring(0, lastSpace)}...`;
  }

  return description;
}
